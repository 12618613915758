<template>
  <div id="app">
    <div class="app-router">
      <transition>
        <keep-alive v-if="this.$route.meta.kep">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </transition>
    </div>
    <NavBar v-if="!this.$route.meta.showtabbarfalse"></NavBar>
  </div>
</template>
<script>


import NavBar from "components/tabBar";
import { jssdk ,getHosDeatil} from "server/home";
import {setStore} from "./config/global";
export default {
  data() {
    return {};
  },
  created(){
    let tokenQuery = this.$route.query.token
    if (tokenQuery) {
      setStore('token', tokenQuery)
    }
  },
  mounted() {
    this.getHosDetail()
    let params = {
      url: window.location.origin,
    };
    // jssdk(params).then((res) => {
    //   this.$store.commit("setjssdk", res);
    // });

  },
  watch: {},
  methods: {
    hiddenPrevent() {
      if (!evt._isScroller) {
        evt.preventDefault();
      }
    },
    // 获取首页医院信息
    getHosDetail(){
      getHosDeatil().then((res)=>{
        if(res){
          this.$store.commit('setHosDetail',res)
        }
      })
    }
  },
  components: {
    NavBar,
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
#app {
  height: 100vh;
  // position: fixed;
  // width:100%;
  // z-index: 1;
  // overflow-y: auto;
  // top: 0;
  // left: 0;
  // overflow: auto;
}
#nprogress .bar {
 background: #DE9C62 !important; //自定义颜色
}
#nprogress .spinner-icon{
  border-top-color: #DE9C62 !important;
  border-left-color: #DE9C62 !important;
}
.app-router {
  height: 100%;
}
body ::v-deep .van-toast {
  background-color: #DE9C62;
  min-width: 5.66667rem;
  border-radius: 0.1rem;
  font-size: 0.48889rem;
}
</style>
<style>
@import "./common/global.css";
body >>> .van-toast {
  background-color: #DE9C62;
  min-width: 5.66667rem;
  border-radius: 0.1rem;
  font-size: 0.48889rem;
}
</style>
