import Vue from 'vue'
import moment, { now } from 'moment'

//人民币过滤器 (元)
Vue.filter('moneyFormat', (value) => {
	return '¥ ' + Number(value).toFixed(2)
})

//人民币过滤器 (元)
Vue.filter('yuanFormat', (value) => {
	return Number(value).toFixed(2) + '元'
})

//人民币过滤器 (元)
Vue.filter('yuanbiaoFormat', (value) => {
	return '¥ ' + Number(value).toFixed(2)
})

//人民币过滤器 (分)
Vue.filter('moneyFormatFen', (value) => {
	return '¥ ' + (Number(value) / 100).toFixed(2)
})

//性别
Vue.filter('sexFormat', (value) => {
	if (value == 'M') {
		return '男'
	} else if (value == 'F') {
		return '女'
	} else {
		return '未知'
	}
})

//支付过滤
Vue.filter('payFormat', (value) => {
	if (value == 'QR_ABC') {
		return '农行手机银行'
	} else if (value == 'QR_ALI') {
		return '支付宝'
	} else if (value == 'QR_WX') {
		return '微信'
	} else if (value == 'UNION') {
		return '银联'
	} else {
		return '未知'
	}
})

//支付方式
Vue.filter('patmethodFormat', (value) => {
	if (value == 1) {
		return "医程通钱包";
	} else if (value == 2) {
		return "银联";
	} else if (value == 3) {
		return "微信";
	} else if (value == 4) {
		return "支付宝";
	} else if (value == 5) {
		return "医保";
	}
})

//0-正常，1-偏高，2-偏低，3-未知

Vue.filter('checkTipFormat', (value) => {
	if (value == '0') {
		return "正常";
	} else if (value == '1') {
		return '偏高';
	} else if (value == '2') {
		return '偏低';
	} else if (value == '3') {
		return "未知";
	} else {
		return value
	}
})

//1=普通, 2=专家, 3=专科, 4=特需, 5=会诊
//
Vue.filter('regtypeFormat', (value) => {
	if (value == '普通') {
		return "1";
	} else if (value == '专家') {
		return '2';
	} else if (value == '专科') {
		return '3';
	} else if (value == '特需') {
		return "4";
	} else if (value == '会诊') {
		return "5";
	}
})

//身份证隐藏
Vue.filter('idcartFormat', (value) => {
	return value.replace(/(\d{4})\d*([0-9a-zA-Z]{4})/, "$1******$2");
})

//姓名隐藏
Vue.filter('noName', (value) => {
	if (value) {
		if (value.length === 2) {
			value = value.substr(0, 1) + '*';
		} else if (value.length > 2) {
			var char = '';
			for (let i = 0, len = value.length - 2; i < len; i++) {
				char += '*';
			}
			value = value.substr(0, 1) + char + value.substr(-1, 1);
		} else {
			value = value;
		}
		return value;
	}
})

//0-订单已取消，1-等待支付，2-支付成功，3-通知HIS失败，4-HIS缴费中
//
Vue.filter('regStatus', (value) => {
	if (value == '0') {
		return "订单已取消";
	} else if (value == '1') {
		return '等待支付';
	} else if (value == '2') {
		return '支付成功';
	} else if (value == '3') {
		return "通知HIS失败";
	} else if (value == '4') {
		return "HIS缴费中";
	}
})

// 預約號
Vue.filter('registerStatus', (value) => {
	if (value == '0') {
		return "正常";
	} else if (value == '1') {
		return '挂满';
	} else if (value == '2') {
		return '预约满';
	} else if (value == '3') {
		return "停诊";
	}
})

// 过滤年龄
Vue.filter('getUserAge', (date) => {
	return `${moment().diff(date, "years")} 岁`;
})

//煎药状态：0：待煎药 1:预约煎药/待煎药 2：正在煎药 3：待取药 4：已取走

Vue.filter('tisanesStatus', (value) => {
	if (value == '0') {
		return "预约煎药";
	} else if (value == '1') {
		return '待煎药';
	} else if (value == '2') {
		return '正在煎药';
	} else if (value == '3') {
		return "待取药";
	} else if (value == '4') {
		return "已取药"
	} else {
		return value
	}
})



Vue.filter('filterString', (value) => {
	if (value.includes('付')) {
		return value.replace('付', '');
	} else {
		return value
	}
})


var regexp = new RegExp('^[\u4E00-\u9FA5A-Za-z0-9]+$');//中文、英文、数字但不包括下划线等符号
var realStr = '';
var value = '你需要验证的字符串'
for (var i = 0; i < value.length; i++) {
	if (regexp.test(value[i])) {
		realStr += value[i];
	}
}
console.log(realStr)


// 证件类型
Vue.filter('cartType', (value) => {
	if (value) {
		if (value == '1') {
			return "中国居民身份证";
		} else if (value == '2') {
			return '居民户口簿';
		} else if (value == '3') {
			return '护照';
		} else if (value == '4') {
			return "军官证";
		} else if (value == '5') {
			return "驾驶证";
		} else if (value == '6') {
			return "港澳居民来往内地通行证";
		} else if (value == '7') {
			return "台湾居民来往内地通行证";
		} else if (value == '99') {
			return "其它法定有效证件";
		}
	} else {
		return "中国居民身份证";

	}
})

// 支付状态
Vue.filter("payStatus", (value) => {
	switch (value) {
		case 0:
			return "等待支付";
		case 10:
			return "支付成功";
		case 20:
			return "等待退款";
		case 21:
			return "退款失败";
		case 22:
			return "部分退款";
		case 23:
			return "全部退款";
		case 24:
			return "退款中止";
		case null:
			return "无需退款";
		default:
			return value;
	}
});
