const home = [
  {
    path: '/home',
    name: 'home',
    component: () => import('views/home/index.vue'),
    children: [
      {
        path: 'introduction',
        name: 'introduction',
        component: () => import('views/home/info/introduction.vue'),
        meta: {
          child: true,
          showtabbarfalse: true,
        }
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('views/home/info/detail.vue'),
        meta: {
          child: true,
          showtabbarfalse: true,
        }
      }
    ]
  }
]
export default home
