import {Toast} from 'vant'
import Vue from 'vue'

const v = new Vue()


const jssdk = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin/jssdk', params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

//医院简介
const getIntroduction = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('article/detail', params)
    v.$bar.done()
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    v.$bar.done()
    console.log(e);
    Toast('请刷新后重试')
  }
}

//动态列表
const getTrendsList = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('hospital_dynamic/index')
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

//动态详情 当列表的links为空时调用
const getTrendsdetail = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('hospital_dynamic/detail', params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

//动态列表
const getContact = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('index/getContact')
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

// 获取医院首页的图片和logo医院名称等
const getHosDeatil = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin/website')
    if (result.status == 200) {
      return result.result
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    // Toast('请刷新后重试')
  }
}

export {
  getIntroduction, getTrendsList, getContact, jssdk, getHosDeatil, getTrendsdetail
}
