import { Toast } from 'vant'
import Vue from 'vue'
import router from 'router/'
import {getPatientlist} from "@/server/login/";
const v = new Vue()

//增加一个改变默认就诊人 在建档成功的时候偷偷改为默认就诊人报错也不提示
const changePatientdefault2 = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin_user/changeDefaultMedicalCard', params)
    if (result.status == 200) {
      return result.result
    } else {
      // Toast({message: result.message,duration: '2000'})
    }
  } catch (e) {
    // console.log(e);
    // Toast('请刷新后重试')
  }
}

//改变默认就诊人
const changePatientdefault = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin_user/changeDefaultMedicalCard',params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 400) {
      Toast(result.message)
      router.push({name:'selectlogin'})
    }else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast({message: result.message,duration: '2000'})
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}


//删除就诊人
const patientdelete = async (params) => {
  let result = ''
  try {
    v.$bar.start()
    result = await v.$axios.post('weixin_user/removeMedicalCard',params)
    if (result.status == 200) {
      getPatientlist().then((res) => {
        // if (res.length ===0) {
        //   this.$toast("请添加就诊人");
        //   this.$router.push({name: "selectlogin"});
        //   return
        // }
        if(res.length > 0){
          v.$store.commit("setUserInfoList", res);
          for (let i = 0; i < res.length; i++) {
            if (res[i].is_default == 1) {
              this.$store.commit("setUserInfo", res[i]);
            }
          }
        }else {
          this.$toast("就診人都被你删除完了！");
        }
      });
      v.$bar.done()
      return result
    } else if (result.status == 400) {
      Toast(result.message)
    }else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

export {
  changePatientdefault,patientdelete,changePatientdefault2
}
