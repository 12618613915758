import { camelCase } from 'lodash'
const requiredModules = require.context('./modules', false, /\.js$/)
const routers = {}

requiredModules.keys().forEach(fileName => {
  // 不加载index.js
  if (fileName === './index.js') return
  // 转为驼峰命名
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
  routers[moduleName] = requiredModules(fileName).default || requiredModules(fileName)
})
export default routers
