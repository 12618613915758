
import nativePlaceFilter from "./nativePlace";
// 检查身份证
const checkIdNumberValid = (tex) => {
  let tip = '输入的身份证号有误，请检查后重新输入！'
  let num = tex
  num = num.toUpperCase()

  let len, re
  len = num.length
  if (len == 0) return false

  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
    return false
  }

  // 验证前两位地区是否有效
  let aCity = {11:'北京',12:'天津',13:'河北',14:'山西',15:'内蒙古',21:'辽宁',22:'吉林',23:'黑龙江',31:'上海',
              32:'江苏',33:'浙江',34:'安徽',35:'福建',36:'江西',37:'山东',41:'河南',42:'湖北',43:'湖南',
              44:'广东',45:'广西',46:'海南',50:'重庆',51:'四川',52:'贵州',53:'云南',54:'西藏',61:'陕西',
              62:'甘肃',63:'青海',64:'宁夏',65:'新疆',71:'台湾',81:'香港',82:'澳门',91:'国外'}

  if (aCity[parseInt(num.substr(0,2))] == null) {
    return false
  }

  // 当身份证为15位时的验证出生日期。
  if (len == 15){
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
    let arrSplit = num.match(re)

    //检查生日日期是否正确
    let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
    let bGoodDay;
    bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
    if (!bGoodDay) {
      return false
    }
  }

  // 当身份证号为18位时，校验出生日期和校验位。
  if (len == 18) {
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
    let arrSplit = num.match(re)
    // 检查生日日期是否正确
    let dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    let bGoodDay
    bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
    if (!bGoodDay) {
      return false
    } else {
      // 检验18位身份证的校验码是否正确。
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      let valnum
      let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)
      let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2')
      let nTemp = 0, i
      for(i = 0; i < 17; i ++){
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      valnum = arrCh[nTemp % 11]
      if (valnum != num.substr(17, 1)){
        return false
      }
    }
  }
  return true;
}

//名字隐藏
const noName =  (name) => {
  var newStr;
  if (name.length === 2) {
      newStr = name.substr(0, 1) + '*';
  } else if (name.length > 2) {
      var char = '';
      for (let i = 0, len = name.length - 2; i < len; i++) {
          char += '*';
      }
      newStr = name.substr(0, 1) + char + name.substr(-1, 1);
  } else {
      newStr = name;
  }
  return newStr;
};

// 检查手机号码
const checkPhone = (val) => {
  let phone = val
  if(!(/^1[3456789]\d{9}$/.test(phone))){
    return false;
  } else {
    return true
  }
}

// 身份证号获取信息
const getInfo = (UUserCard, num) => {
  let str = UUserCard + ''
  if (num === 1) {
    // 获取出生日期
    let birth = str.substr(6, 4) + '-' + str.substr(10, 2) + '-' + str.substr(12, 2)
    return birth
  }
  if (num === 2) {
    // 获取性别
    if (parseInt(str.substr(16, 1)) % 2 === 1) {
      // 男
      return 'M'
    } else {
      // 女
      return 'F'
    }
  }
  if(num==3){
    // 获取年龄
    let myDate = new Date()
    let month = myDate.getMonth() + 1
    let day = myDate.getDate()
    let age = myDate.getFullYear() - str.substr(6, 10) - 1
    if (str.substr(10, 12) < month || str.substr(10, 12) == month && str.substr(12, 14) <= day) {
      age++
    }
    return age
  }
  if(num==4){
    // 获取籍贯  
        let nativePlace =''
          nativePlaceFilter.nativePlaceFilter.map((item)=>{
           if(item.code == UUserCard.substr(0,6) ){
            nativePlace = item.title
           }
        })
    return nativePlace
  }
}

/**
 * 两个小数精确相加
 */

const bcadd = (num1, num2, length) => {
  length = length || 2
  var multiple = Math.pow(10, length + 1)
  num1 = isNaN(num1) ? 0 : parseInt(num1 * multiple)
  num2 = isNaN(num2) ? 0 : parseInt(num2 * multiple)
  var result = ((num1 + num2) / multiple).toFixed(length + 1).toString()
  return result.substr(0, result.length - 1)
}

/**
* 两个小数精确相减
*/
const bcsub = (num1, num2, length) => {
  length = length || 2
  var multiple = Math.pow(10, length + 1)
  num1 = isNaN(num1) ? 0 : parseInt(num1 * multiple)
  num2 = isNaN(num2) ? 0 : parseInt(num2 * multiple)
  var result = ((num1 - num2) / multiple).toFixed(length + 1).toString()
  return result.substr(0, result.length - 1)
}

/**
* 两个小数精确相乘
*/
const bcmul = (num1, num2, length) => {
  length = length || 2
  var multiple = Math.pow(10, length + 1)
  num1 = isNaN(num1) ? 0 : parseInt(num1 * multiple)
  num2 = isNaN(num2) ? 0 : parseInt(num2 * multiple)
  if (num1 !== 0 && num2 !== 0) {
    var result = ((num1 * num2) / Math.pow(multiple, 2)).toFixed(length + 1).toString()
    return result.substr(0, result.length - 1)
  } else {
    return '0'
  }
}

/**
* 两个小数精确相除
*/
const bcdiv = (num1, num2, length) => {
  length = length || 2
  var multiple = Math.pow(10, length + 1)
  num1 = isNaN(num1) ? 0 : parseInt(num1 * multiple)
  num2 = isNaN(num2) ? 0 : parseInt(num2 * multiple)
  if (num1 !== 0 && num2 !== 0) {
    var result = (num1 / num2).toFixed(length + 1).toString()
    return result.substr(0, result.length - 1)
  } else {
    return '0'
  }
}

// 保留?位小数
const numFlo = (params, num) => {
  let newpar = parseFloat(params)
  let reg = /^[0-9]+.?[0-9]*$/
  if (reg.test(newpar)) {
    let newNum = newpar.toFixed(num)
    return newNum
  } else {
    alert('请输入数字')
    return false
  }
}

const floatObj = () => {

  /*
   * 判断obj是否为一个整数
   */
  function isInteger(obj) {
    return Math.floor(obj) === obj
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  function toInteger(floatNum) {
    var ret = {times: 1, num: 0}
    var isNegative = floatNum < 0
    if (isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    var strfi  = floatNum + ''
    var dotPos = strfi.indexOf('.')
    var len    = strfi.substr(dotPos+1).length
    var times  = Math.pow(10, len)
    var intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10)
    ret.times  = times
    if (isNegative) {
      intNum = -intNum
    }
    ret.num = intNum
    return ret
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   *
   * @param a {number} 运算数1
   * @param b {number} 运算数2
   * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
   * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
   * 
   */
  function operation(a, b, digits, op) {
    var o1 = toInteger(a)
    var o2 = toInteger(b)
    var n1 = o1.num
    var n2 = o2.num
    var t1 = o1.times
    var t2 = o2.times
    var max = t1 > t2 ? t1 : t2
    var result = null
    switch (op) {
      case 'add':
        if (t1 === t2) { // 两个小数位数相同
          result = n1 + n2
        } else if (t1 > t2) { // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else { // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 'multiply':
        result = (n1 * n2) / (t1 * t2)
        return result
      case 'divide':
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  }

  // 加减乘除的四个接口
  function add(a, b, digits) {
    return operation(a, b, digits, 'add')
  }
  function subtract(a, b, digits) {
    return operation(a, b, digits, 'subtract')
  }
  function multiply(a, b, digits) {
    return operation(a, b, digits, 'multiply')
  }
  function divide(a, b, digits) {
    return operation(a, b, digits, 'divide')
  }

  // exports
  return {
    add: add,
    subtract: subtract,
    multiply: multiply,
    divide: divide
  }
};

export {
  checkIdNumberValid,
  checkPhone,
  getInfo,
  bcadd,
  bcsub,
  bcmul,
  bcdiv,
  numFlo,
  floatObj
}
