import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './router'
import title from 'common/title'
import {reload} from '@/config/global'
import store from './../store/index'
import {login} from 'server/login/index'
import {setStore, getStore, getQueryString} from './../config/global'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/home'
},]
Object.values(routers).forEach(router => {
  routes.push(...router)
})
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//路由前置守卫
router.beforeEach((to, from, next) => {
  // 去哪一个页面和从哪个页面来都存起来
  // console.warn(to.path,'to.path')
  // console.warn(from.path,'from.path')
	to.path != '' ? store.commit('setToPage',to.path) : ''
	from.path != '' ? store.commit('setFromPage',from.path) : ''

  if (process.env.NODE_ENV === 'development') {
    // setStore("token", "416109C6-0735-0F12-BE8A-BFAB9637F623");
    // 测试环境
    // setStore("token", "8D00FC4C-0F5C-7E4C-9D60-EA456A6445B3");
    // 正式环境
    // setStore("token", "68BE4284-7AB2-14F4-5ACC-F99F8FEA9D58");
    next()
  } else {
    let token = getStore('token')
    // alert(window.location.href.token);
    if (token) {
      next()
    } else {
      let tokenQuery = getQueryString(token)
      // let token = getStore('token')
      if (tokenQuery) {
        setStore('token', tokenQuery)
        next()
      } else {
        // 微信授权
        reload()
      }
    }
  }
})

//路由后置守卫
router.afterEach((to, from) => {
  if (to.path === "/server") store.commit("SET_MEDICINE_TYPE", 0);
  window.document.title = title[to.name];
})


export default router
