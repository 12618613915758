import Vue from "vue";
import componentLoading from "./index.vue";

const comLoading = Vue.extend(componentLoading);

const instance = new comLoading({
  el: document.createElement("div"),
});

instance.show = false;
const loading = {
  show(obj = {}) {
    instance.show = true;
    instance.text = obj.text || '加载中'
    document.body.appendChild(instance.$el);
  },
  hide() {
    instance.show = false;
  },
};
export default {
  install() {
    if (!Vue.$ybLoading) {
      Vue.$ybLoading = loading;
    }
    Vue.mixin({
      created() {
        this.$ybLoading = Vue.$ybLoading;
      },
    });
  },
};