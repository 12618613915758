// import { get , post } from './../api'
import { Toast } from 'vant'
import Vue from 'vue'
import router from 'router/'
const v = new Vue()

//微信登录
const login = async (params) => {
  // let result = ''
  // var l = v.$loading({ fullscreen: true ,text:'加载中'})
  // try {
  //   result = await v.$axios.post('login/getCode')
  //   l.close()
  //   if (result.status == 200) {
  //     return result.result
  //   } else if (result.status == 401) {
  //     Toast(result.message)
  //
  //   } else {
  //     Toast(result.message)
  //
  //   }
  // } catch (e) {
  //   l.close()
  //   console.log(e);
  // }
}

//获取就诊卡数量
const getPatientlist = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin_user/myPatient',params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 400) {
      Toast('暂无就诊人，请先绑定就诊卡')
      router.push({name:'selectlogin'})
    }else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

//新建就诊卡
const patientadd = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin_user/createMedicalCard',params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 400) {
      Toast(result.message)
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)
    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')

  }
}

//绑定就诊卡
const patientbind = async (params) => {
  let result = ''
  try {
    result = await v.$axios.post('weixin_user/bindMedicalCard',params)
    if (result.status == 200) {
      return result.result
    } else if (result.status == 400) {
      Toast(result.message)
    } else if (result.status == 401) {
      Toast(result.message)
    } else {
      Toast(result.message)

    }
  } catch (e) {
    console.log(e);
    Toast('请刷新后重试')
  }
}

export {
  login,
  patientadd,
  patientbind,
  getPatientlist
}
