<template>
  <div>
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item to="/home">
        首页
        <template #icon="props">
          <img :src="props.active ? home.active : home.inactive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/server">
        服务大厅
        <template #icon="props">
          <img :src="props.active ? server.active : server.inactive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/mine">
        个人中心
        <template #icon="props">
          <img :src="props.active ? mine.active : mine.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {setStore, getStore} from "config/global.js";

export default {
  data() {
    return {
      active: this.$store.state.active,
      home: {
        active: require("assets/tabbar/ico_home_tab_press@3x.png"),
        inactive: require("assets/tabbar/home.png")
      },
      server: {
        active: require("assets/tabbar/ico_service_tab@3x.png"),
        inactive: require("assets/tabbar/ico_service_tab.png")
      },
      mine: {
        active: require("assets/tabbar/ico_mine_tab@3x.png"),
        inactive: require("assets/tabbar/ico_mine_tab.png")
      }
    };
  },
  watch: {
    "$store.state.active"(value) {
      this.active = value;
    }
  },
  mounted() {
    let active = this.getActive();

    if (active) {
      this.active = Number(active);
      switch (this.active) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/server");
          break;
        case 2:
          this.$router.push("/mine");
          break;
      }
    }
  },
  methods: {
    getActive() {
      let active = getStore("active");
      if (!active) {
        switch (window.location.pathname.toLocaleLowerCase()) {
          case '/home':
            active = 0;
            break;
          case '/server':
            active = 1;
            break;
          case '/mine':
            active = 2;
            break;
        }
        switch (window.location.hash.toLocaleLowerCase()) {
          case '#/home':
            active = 0;
            break;
          case '#/server':
            active = 1;
            break;
          case '#/mine':
            active = 2;
            break;
        }
      }
      return active;
    },

    onChange(index) {
      setStore("active", index);
      this.$store.commit("setActive", index);
    }
  }
};
</script>

<style scoped>
::v-deep .van-tabbar{
  /*background: red;*/
  height: 7%;
  /*background-color: #fbf7f3;*/
}

::v-deep .van-tabbar-item--active{
  color: #DE9C62;
}


</style>
