import Vue from 'vue'
import './server/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant'
import './plugins/elment'
import 'lib-flexible'
import 'amfe-flexible/index.js'
import Vconsole from 'vconsole'
import VueBarcode from '@xkeshi/vue-barcode';
import moment from 'moment'
import 'moment/locale/zh-cn'
import './config/filter'
import {removeStore, getQueryString, setStore} from "config/global";
import myBtn from "./components/button/index.js"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Day from 'dayjs' //日期处理工具
import 'dayjs/locale/zh-cn'
import './plugins/elemnt_scss.scss'
import loading from '@/components/Loading/index'
import {floatObj} from '@/common/common'
import imgBase64 from "@/common/imgBase64"
import ckBtn from "./components/newButton/index.js";
import ybloading from '@/components/ybLoading/index'
let float = floatObj()
Vue.use(myBtn)
Vue.use(ckBtn);
Vue.component('barcode', VueBarcode);
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.prototype.$bar = NProgress
Vue.prototype.$day = Day
Vue.prototype.$float = float
Vue.prototype.$imgBase64 = imgBase64.imgBase64
Vue.use(loading);
Vue.use(ybloading);

Day.locale('zh-cn')

// 删除tabbar的历史选项
removeStore('active');

let tokenQuery = getQueryString('token')
// alert(tokenQuery)
if (tokenQuery) {
  setStore('token', tokenQuery)
}

/** 解构参数 */
const {NODE_ENV, VUE_APP_EDITION, VUE_APP_BASEURL} = process.env;
const logColor = 'color:#e100fa;font-weight:700;font-size:14px;';

if (NODE_ENV !== 'production') {
	new Vconsole()
	const envType = new Map([
		['development', '服务器开发'],
		['test', '测试开发'],
		['dev', '测试开发'],
		['production', '正式环境']
	]);
	console.log(`%c 开发环境=> ${NODE_ENV} - ${envType.get(NODE_ENV)}`, logColor);
	console.log(`%c 当前版本=> ${moment(+VUE_APP_EDITION, 'YYMMDDHHmmss')
		.format('YYYY-MM-DD HH:mm:ss')}`, logColor);
	console.log(`%c 接口地址=> ${VUE_APP_BASEURL}`, logColor);
	console.log(`%c 接口文档=> ${VUE_APP_BASEURL}/apidoc/index.html`, logColor);
	console.log(`%c 接口环境=> ${VUE_APP_BASEURL.includes('test') ? '测试接口' : '正式接口'}`, logColor);
}

// router.beforeEach((to, from, next) => {
//   NProgress.start()
//   next()
// })
//
// router.afterEach(() => {
//   NProgress.done()
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
