import {changePatientdefault,patientdelete} from "server/mine";
import {getPatientlist} from "server/login";
import Vue from 'vue'
import router from "router/index";
import { Dialog } from 'vant';
const v = new Vue()

/**
 * 找到默认就诊人
 * @param userList
 * @returns {any}
 */
const findDefaultUser = (userList)=>{
  if(!Array.isArray(userList)){
    v.$toast('用户列表必须是一个数组')
  }
  for(let item of userList){
    if(item.is_default === 1){
      return item
    }
  }
  return undefined
}



export default{

  /**
   * 切换默认就诊人
   * @param state
   * @param commit
   * @param index 被选中的就诊人
   * @returns {Promise<void>}
   */
  async changeDefaultUser({state,commit,dispatch},index){
    return new Promise(async (resolve, reject) => {
      await changePatientdefault({ patient_id: state.userInfoList[index].id }).then((res) => {
        dispatch('getAllUser',{isReFlush:true}).then((res)=>{
          commit('setUserInfo', state.userInfoList[index])
          v.$toast("切换成功");
          resolve(res)
        }).catch((e)=>{
          v.$toast(e);
          reject(e)
        })
      }).catch((e)=>{
        v.$toast("切换失败，请重试！");
        reject(e)
      });
    })

  },

  /**
   * 获取所有就诊人
   * @param state
   * @param commit
   * @param {object} options  isReFlush是否强制刷新不从vuex获取,isPush是否进入建卡页面
   * @returns {Promise<void>}
   */
  async getAllUser({state,commit},options={}){
    if(!options.hasOwnProperty('isReFlush')){
      options.isReFlush = false
    }
    if(!options.hasOwnProperty('isPush')){
      options.isPush = true
    }
    let dropList = {}
    dropList.user = []
    dropList.select = ''
    return new Promise(async (resolve, reject) => {
      if(state.userInfoList.length>0 && !options.isReFlush){
        for (let [index, item] of state.userInfoList.entries()) {
          const user = {
            text: item.name,
            value: index,
            id: item.id,
            medical_card: item.medical_card,
          };
          dropList.user.push(user);
          if (item.is_default === 1) {
            dropList.select = index;
          }
        }
        resolve(true)
        commit('setUserInfo',findDefaultUser(state.userInfoList))
        commit('setUserDropList',dropList)
      }else{
        await getPatientlist().then((res)=>{
          if(!Array.isArray(res)){
            reject('返回的就诊人列表必须是一个数组')
          }
          if(res.length>0){
            for (let [index, item] of res.entries()) {
              const user = {
                text: item.name,
                value: index,
                id: item.id,
                medical_card: item.medical_card,
              };
              dropList.user.push(user);
              if (item.is_default === 1) {
                dropList.select = index;
              }
            }
            commit('setUserDropList',dropList)
            commit('setUserInfoList',res)
            commit('setUserInfo',findDefaultUser(res))
            resolve(res)
          }else {
            if(options.isPush){
              commit('SET_BEFORE_INSIDE',window.location.hash.split('#')[1])
              v.$toast('还没有就诊人，请先绑定')
              router.push({name:'selectlogin'})
            }
            reject(false)
          }
        }).catch((e)=>{
          v.$toast(e)
          reject(false)
        })
      }
    })
  },

  //检查是否有默认就诊人
  async checkHasUser({state,dispatch}){
    return new Promise(async (resolve, reject) => {
      await dispatch('getAllUser').then(()=>{
        resolve(true)
      }).catch(()=>{
        reject(false)
      })
    })
  },


}
