import {login} from 'server/login'
/**
 * 本地化存储
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 本地化获取
 */
export const getStore = (name) => {
  if (!name) return;
  return window.localStorage.getItem(name)
}

/**
 * 本地化删除
 */
export const removeStore = (name) => {
  if (!name) return;
  return window.localStorage.removeItem(name)
}

//重新登录
export const reload = () => {
  localStorage.removeItem('token');
  let hash = encodeURIComponent(window.location.hash)
  let url = `${process.env.VUE_APP_BASEURL}/api/weixin/h5Login?redirect_uri=${window.location.origin}${window.location.pathname}${hash}`
  window.location.href = url
}

// 获取参数
export const getQueryString = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = '?'.concat(window.location.href.split('?')[1]).substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
