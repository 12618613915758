<template>
  <div v-if="show">
    <van-overlay class="box" :custom-style="{background : '#fff'}" :show="true" @click="show = false">
      <div class="wrapper" @click.stop>
        <img class="img" src="../../assets/medicarePayment/loading.gif" alt="">
        <div class="text">{{ text }}</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    show: Boolean,
    text:String
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: fixed;
  z-index: 9999;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 3.2rem;
    .img{
      width: 2.24rem;
      height: 2.24rem;
      margin-bottom: 0.53333rem;
    }
    .text{
      font-size: 0.42667rem;
      color: #3B71e8;
      width: 7.467rem;
      text-align: center;
    }
  }
}
</style>