"use strict";

import Vue from 'vue';
import axios from "axios";
import moment from 'moment'
import { reload } from './../config/global'
import vue from 'vue'

const v = new vue()
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers['Content-Type'] = 'text/plain';
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

let config = {
  baseURL: `${process.env.VUE_APP_BASEURL}/api/`,
  timeout: 30 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const _axios = axios.create(config);

// 取消重复请求
// let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
// let cancelToken = axios.CancelToken;
// let removePending = (config) => {
//   for (let p in pending) {
//     if (pending[p].u === config.url + '&' + config.method) { //当当前请求在数组中存在时执行函数体
//       pending[p].f(); //执行取消操作
//       pending.splice(p, 1); //把这条记录从数组中移除
//     }
//   }
// }

function qianming(params) {
  return window.paramSign(params)
}

_axios.interceptors.request.use(
  function (config) {
    // removePending(config); //在一个ajax发送前执行一下取消操作
    // config.cancelToken = new cancelToken((c) => {
    //   // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
    //   pending.push({
    //     u: config.url + '&' + config.method,
    //     f: c
    //   });
    // });

    let obj = {}
    let token
    let timestamp = parseInt(moment().valueOf() / 1000)
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token")
      config.data = {
        ...config.data,
        timestamp: timestamp,
        machine_code: 'WeChat-SA',
        // machine_code: 'ZW01',

        token
      }
    } else {
      // reload()
      config.data = {
        ...config.data,
        machine_code: 'WeChat-SA',
        timestamp: timestamp,
      }
    }
    obj = qianming(config.data);
    if (config.url.includes('?')) {
      config.url = config.url + `&signature=${obj.signature}`
    } else {
      config.url = config.url + `?signature=${obj.signature}`
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);

  }
);

_axios.interceptors.response.use(
  function (response) {
    let _this = this
    // removePending(response.config);
    if (response.data.status == 401) {
      localStorage.removeItem('token')
      reload()
    }
    if (response.status >= 500) {
      vue.$toast(response.data.message)
    }
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
