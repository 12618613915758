const login = [{
  path: '/login',
  name: 'selectlogin',
  component: () => import('views/login/select.vue'),
  meta: {
    showtabbarfalse: true,
    index: 0
  },
  children: [{
      path: 'createCard',
      name: 'createCard',
      component: () => import('views/login/signup/createCard.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1,
      },
    },
    {
      path: 'createChildCard',
      name: 'createChildCard',
      component: () => import('views/login/signup/createChildCard.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1,
      },
    },
    {
      path: 'bindCard',
      name: 'bindCard',
      component: () => import('views/login/signin/bindCard.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1,
      },
    },
    {
      path: 'bindChildCard',
      name: 'bindChildCard',
      component: () => import('views/login/signin/bindChildCard.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1,
      },
    },
    {
      path: 'bindSuccess',
      name: 'bindSuccess',
      component: () => import('views/login/signin/bindSuccess.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1
      },
    },
    {
      path: 'createSuccess',
      name: 'createSuccess',
      component: () => import('views/login/signup/createSuccess.vue'),
      meta: {
        showtabbarfalse: true,
        log: true,
        index: 1
      },

    }
  ]
}]
export default login
