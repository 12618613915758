 const family = [
 // 填写个人信息
    {
      path: '/ContractedServices',
      name: 'contracted',
      component: () => import('views/server/familyDoc/servicePack/ContractedServices.vue'),
      meta: {
        title: '家庭医生（团队）签约服务协议书',
        showtabbarfalse: true,
        active: 1,
        kep:true
      }
    },
    // 确定信息
    {
      path: '/confirmMsg',
      name: 'confirmMsg',
      component: () => import('views/server/familyDoc/servicePack/confirmMsg.vue'),
      meta: {
        title: '预约及确认信息',
        showtabbarfalse: true,
        active: 1,
        kep: false
      }
    },
    // 家庭医生签约记录
    {
      path: '/signingRecord',
      name: 'signingRecord',
      component: () => import('views/server/familyDoc/servicePack/signingRecord.vue'),
      meta: {
        showtabbarfalse: true,
        title: '签约医生记录',
        active: 1,
        kep:true,
      }
    },
    // 一般人服务包详情
    {
      path: '/ordinaryPeople',
      name: 'ordinaryPeople',
      component: () => import('views/server/familyDoc/servicePack/ordinaryPeople.vue'),
      meta: {
        title: '一般人服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true,
      }
    },
    // 儿童服务包详情
    {
      path: '/children',
      name: 'children',
      component: () => import('views/server/familyDoc/servicePack/children.vue'),
      meta: {
        title: '儿童服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true,
      }
    },
    // 孕妇服务包详情
    {
      path: '/pregnantWoman',
      name: 'pregnantWoman',
      component: () => import('views/server/familyDoc/servicePack/pregnantWoman.vue'),
      meta: {
        title: '孕妇服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true,
      }
    },
    // 糖尿病服务包详情
    {
      path: '/diabetes',
      name: 'diabetes',
      component: () => import('views/server/familyDoc/servicePack/diabetes.vue'),
      meta: {
        title: '糖尿病服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true,
      }
    },
    // 高血压服务包详情
    {
      path: '/hypertension',
      name: 'hypertension',
      component: () => import('views/server/familyDoc/servicePack/hypertension.vue'),
      meta: {
        title: '高血压服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true
      }
    },
    // 老年人服务包详情
    {
      path: '/aged',
      name: 'aged',
      component: () => import('views/server/familyDoc/servicePack/aged.vue'),
      meta: {
        title: '老年人服务包',
        showtabbarfalse: true,
        active: 1,
        kep: true
      }
    },
    // 老年人服务包详情
    {
      path: '/explain',
      name: 'explain',
      component: () => import('views/server/familyDoc/servicePack/explain.vue'),
      meta: {
        title: '签约服务协议书说明',
        active: 1,
        showtabbarfalse: true,
        kep: true
      }
    },
    {
      path: '/elcsign',
      name: 'elcsign',
      component: () => import('views/server/familyDoc/servicePack/elcsign.vue'),
      meta: {
        title: '电子签名录入',
        active: 1,
        showtabbarfalse: true,
        kep:true
      }
    },
  ]

  export default family
