<script>
export default {
  name: "ckBtn",
  data() {
    return {
      flag: false,
    }
  },
  props: {
    //按钮类型
    type: {
      type: String,
      default: "normal",
    },
    //文字
    text: {
      type: String,
      default: "确认",
    },
    //是否loading
    loading: {
      type: Boolean,
      default: false,
    },
    //loading提示文字
    tips: {
      type: String,
      default: "加载中...",
    },
    //大小
    size: {
      type: String,
      default: "normal"
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  render(h) {
    return h("div", {
      //options
      //样式
      class: {
        ckBtn: true,
        "btnSuccess": this.type === "success",
        "btnFail": this.type === "fail",
        "btnWarning": this.type === "warning",
        'normal': this.type === "normal",
        'white': this.type === "white",
        'disabled': this.type === "disabled",
        'btnColorBlue': this.type === "btnColorBlue",
        size: true,
        'small': this.size === 'small',
        'large': this.size === 'large',
        'mini': this.size === 'mini'
      },
      //文字
      domProps: {
        innerText: this.text,
      },
      //事件
      on: {
        click: this.click,
      },
      scopedSlots: {
        default: props => h('span', props.text)
      },
    });
  },
  methods: {
    click(event) {
      if(this.flag){

      }else{
        this.$emit("click");
      }
    },
  },
  watch: {
    loading(newVal, oldVal) {
      let a = document.getElementsByClassName('ckBtn')[0]
      if (newVal === true) {
        this.flag = true;
        if (Object.prototype.hasOwnProperty.call(a.classList, 'normal')) {
          a.classList.remove('normal')
        }
        let str = `<div id="ck-btnLoading">
                      <div class="ck-loading">
                        <div class="ck-btnLoading-in ck-loading-line1"></div>
                        <div class="ck-btnLoading-in ck-loading-line2"></div>
                        <div class="ck-btnLoading-in ck-loading-line3"></div>
                        <div class="ck-btnLoading-in ck-loading-line4"></div>
                        <div class="ck-btnLoading-in ck-loading-line5"></div>
                      </div>
                      <div id="ckBtnTips" style="display: flex;align-items: center;"></div>
                    </div>`
        a.classList.add('disabled')
        a.innerHTML = str
        let b = document.getElementById('ckBtnTips')
        b.innerText = this.tips
      }else{
        this.flag = false
        a.classList.remove('disabled')
        a.innerHTML = this.text
      }
    }
  },
  mounted() {
    if (this.disabled === true) {
      this.flag = true;
      let a = document.getElementsByClassName('ckBtn')[0]
      a.classList.add('disabled')
    }
  },
  beforeDestroy() {
    this.flag = false
  }
};
</script>

<style lang="scss">
@import "./index";
</style>
<style lang="scss" scoped>

.ckBtn {
  font-size: 16px;
  margin: 0 auto;
  color: #ffffff;
  letter-spacing: 0;
  border-radius: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSuccess {
  background-color: rgb(151, 224, 109) !important;
}

.btnFail {
  background-color: rgb(224, 50, 50) !important;
}

.btnWarning {
  background-color: rgb(255, 196, 68) !important;
}

.disabled {
  background-color: #C1BAB2 !important;
}

.normal {
  background-color: #DD9B61;
}

.btnColorBlue {
  background-color: #3b71e8;
}

.white {
  background-color: unset !important;
  border: 1px solid #A48D71 !important;
  color: #A48D71 !important;
}

.size {
  width: 100%;
  height: 43px;
  font-family: PingFang SC;
  border-radius: 2px;
  color: #FFFFFF;
}

.small {
  width: 169px;
  height: 47px;
  font-size: 15px;
  font-weight: bold;
}

.large {
  width: 351px;
  height: 47px;
  font-size: 16px;
  font-weight: 400;
}

.mini {
  width: 80px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
}

#ckBtnTips {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
