<script>
export default {
  name: "myBtn",
  props: {
    //按钮类型
    type: {
      type: String,
      default: "normal",
    },
    //文字
    text: {
      type: String,
      default: "确认",
    },
  },
  render(h) {
    return h("div", {
      //options
      //样式
      class: {
        btn: true,
        "btn-success": this.type === "success",
        "btn-fail": this.type === "fail",
        "btn-warning": this.type === "warning",
        'normal': this.type === "normal",
      },
      //文字
      domProps: {
        innerText: this.text,
      },
      //事件
      on: {
        click: this.click,
      },
    });
  },
  methods: {
    click() {
      this.$emit("onClick");
    },
  },
};
</script>

<style scoped>
.btn {
  width: 90%;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 10px auto;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.btn-success {
  background-color: rgb(151, 224, 109);
}
.btn-fail {
  background-color: rgb(224, 50, 50);
}
.btn-warning {
  background-color: rgb(255, 196, 68);
}
.normal {
  background: #DE9C62;
}
</style>
