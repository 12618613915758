const mine = [
  {
    path: '/mine',
    name: 'mine',
    component: () => import('views/mine/index.vue'),
    meta:{
      login:true,
      child:false
    },
    children:[
      {
        path: 'minePaint',
        name: 'minePaint',
        component: () => import('views/mine/minePaint/index.vue'),
        meta:{
          login:true,
          child:true,
          showtabbarfalse: true,
        }
      },
      {
        path: 'minePaintDetail',
        name: 'minePaintDetail',
        component: () => import('views/mine/minePaint/minePaintDetail.vue'),
        meta:{
          login:true,
          showtabbarfalse: true,
          child:true,
        }
      },
      {
        path: 'heathlyCodeIndex',
        name: 'heathlyCodeIndex',
        component: () => import('views/mine/helthQr/index.vue'),
        meta:{
          login:true,
          showtabbarfalse: true,
          child:true,
        }
      },
      {
        path: 'heathlyCodeSignQr',
        name: 'heathlyCodeSignQr',
        component: () => import('views/mine/helthQr/signQr.vue'),
        meta:{
          login:true,
          showtabbarfalse: true,
          child:true,
        }
      }
    ]
  },
  
]

export default mine