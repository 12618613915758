<template>
  <div id="loading" v-if="show">
    <van-loading vertical size="60px" text-size="20px" color="#DE9C62">
      {{ text }}
    </van-loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      text: '',
    }
  },
  methods: {
  }
}
</script>

<style scoped>
#loading {
  width: 140px;
  height: 120px;
  position: fixed;
  margin:auto;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 253, 253, 0.5);
  border: 1px #c9c9c9 dashed;
}

.van-loading >>> .van-loading__text {
  font-family: PingFangSC-Regular;
  color: #DE9C62;
}

</style>
