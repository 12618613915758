export default {
	//tabBar选择
	setActive(state, data) {
		state.active = data;
	},
	//设置当前选中的用户
	setUserInfo(state, data) {
		state.userInfo = data;
	},
	//设置全部就诊人信息
	setUserInfoList(state, data) {
		state.userInfoList = data;
	},
	//设置下拉框信息
	setUserDropList(state, data) {
		state.userDropList = data;
	},
	setjssdk(state, data) {
		state.jssdk = data;
	},
	setCheckUpDetail(state, data) {
		state.checkUpDetail = data;
	},
	setCheckOutDetail(state, data) {
		state.checkOutDetail = data;
	},
	commit_Info(state, data) {
		state.familyDocInfo = { ...state.familyDocInfo, ...data };
	},
	getName(state, name) {
		state.name = name;
	},
	getActive(state, active) {
		state.active = active;
	},
	// 医院信息
	setHosDetail(state, data) {
		state.hosDetail = data;
	},
	setGo(state, data) {
		state.isGo = data;
	},
	setDeptId(state, data) {
		state.deptid = data;
	},
	SET_BEFORE_INSIDE: (state, data) => {
		state.beforeInside = data;
	},
  SET_MEDICINE_TYPE: (state, data)=>{
    state.medicineType = data;
  },
	 /****存入页面来源*****/
	 setFromPage(state,data){
		state.fromPage = data
	},
	setToPage(state,data){
		state.toPage = data
	},
};

